<template>
  <!-- 提货信息 -->
  <div class="tihuo">
    <img class="backpackimg" src="@/assets/images/user/backpack/beibao.png" alt />
    <div class="header">
      <div class="screenbnt" @click="gunScreen('新申请')" :class="status == 1 ? 'yellow' : 'kong'">新申请</div>
      <div
        class="screenbnt"
        @click="gunScreen('等待发货')"
        :class="status == 2 ? 'yellow' : 'kong'"
      >等待发货</div>
      <div
        class="screenbnt"
        @click="gunScreen('发货成功')"
        :class="status == 3 ? 'yellow' : 'kong'"
      >发货成功</div>
      <div class="screenbnt" @click="gunScreen('已退回')" :class="status == 4 ? 'yellow' : 'kong'">已退回</div>
    </div>
    <div class="main">
      <div class="gunbox" v-for="(item, index) in gunlist" :key="index">
        <div class="gh">{{ item.deliveryState }}</div>
        <img :src="item.oImg" alt class="gm" />
        <div class="gf">{{ item.oName }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getExtractBillAPI } from "../../../api/index";
export default {
  data() {
    return {
      gunlist: [],
      state: [],
      statetext: "",
      ScreenArr: [],
      ScreenArrs: [],
      arr: [],
      arrs: [],
      status: 1,
      num: 1
    };
  },
  mounted() {
    getExtractBillAPI().then(res => {
      // console.log(res.data.rows);
      this.gunlist = res.data.rows;
      for (let index = 0; index < this.gunlist.length; index++) {
        if (this.gunlist[index].deliveryState === "0") {
          this.state.push("新申请");
        } else if (this.gunlist[index].deliveryState === "1") {
          this.state.push("等待发货");
        } else if (this.gunlist[index].deliveryState === "2") {
          this.state.push("发货成功");
        } else if (this.gunlist[index].deliveryState === "4") {
          this.state.push("已退回");
        }
      }
      for (let index = 0; index < this.state.length; index++) {
        this.gunlist[index].deliveryState = this.state[index];
      }
      this.arrs = this.gunlist;
    });
  },
  methods: {
    next() {
      this.num++;
    },
    prev() {
      this.num--;
    },
    gunScreen(screen) {
      if (screen == "新申请") {
        this.status = 1;
      } else if (screen == "等待发货") {
        this.status = 2;
      } else if (screen == "发货成功") {
        this.status = 3;
      } else if (screen == "已退回") {
        this.status = 4;
      }
      this.arr = [];
      for (const item of this.arrs) {
        if (item.deliveryState === screen) {
          this.arr.push(item);
        }
        this.gunlist = this.arr;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.tihuo {
  width: 100%;
  height: 100%;
  background-image: url("../../../assets/images/user/backpack/bg2.png");
  background-size: 100% 100%;

  .header {
    display: flex;
    margin-left: 20px;

    .screenbnt {
      width: 60px;
      font-size: 12px;
    }

    .yellow {
      color: rgba(255, 224, 39, 1);
    }

    .kong {
      color: #fff;
    }
  }

  .main {
    width: 520px;
    height: 150px;
    margin: 10px auto;
    display: flex;
    flex-wrap: wrap;
    // align-items: center;
    // justify-content: space-evenly;
    overflow-y: scroll;

    .gunbox {
      width: 90px;
      height: 100px;
      background-image: url("~@/assets/images/user/backpack/bg1.png");
      background-size: 100% 100%;

      .gh {
        height: 10px;
        padding-top: 10px;
        padding-right: 10px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 10px;
        color: rgba(254, 227, 3, 1);
      }

      .gm {
        display: block;
        height: 50px;
        width: 90%;
        margin: auto;
      }

      .gf {
        width: 80%;
        margin: auto;
        font-size: 10px;
        color: #fff;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .footer {
    width: 490px;
    margin: 16px auto;
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 14px;

    .fli {
      flex: none;
      width: 14px;
      height: 20px;
    }
  }
}
@media (max-width: 550px) {
  .tihuo {
    width: 100%;
    height: 100%;
    background: none;
    .backpackimg{
      display: none;
    }
    .header {
      display: flex;
      margin-left: 20px;

      .screenbnt {
        width: 60px;
        font-size: 12px;
      }

      .yellow {
        color: rgba(255, 224, 39, 1);
      }

      .kong {
        color: #fff;
      }
    }

    .main {
      width: 98%;
      height: 460px;
      margin: 10px auto;
      display: flex;
      flex-wrap: wrap;
      // align-items: center;
      // justify-content: space-evenly;
      overflow: scroll;

      .gunbox {
        width: 50%;
        height: 130px;
        background-image: url("../../../assets/images/user/backpack/bg1.png");
        background-size: 100% 100%;

        .gh {
          height: 10px;
          padding-top: 16px;
          padding-right: 10px;
          display: flex;
          justify-content: flex-end;
          align-items: center;

          div {
            font-size: 15px;
            color: rgba(254, 227, 3, 1);
          }

          img {
            flex: none;
            margin-right: 4px;
            width: 15px;
            height: 15px;
          }
        }

        .gm {
          display: block;
          height: 66px;
          width: 50%;
          margin: auto;
        }

        .gf {
          width: 80%;
          margin: auto;
          font-size: 14px;
          color: #fff;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }

    .footer {
      width: 50%;
      margin: 16px auto;
      display: flex;
      justify-content: space-between;

      .footer-left {
        display: none;
        align-items: center;
        color: #fff;
        font-size: 14px;

        .fli {
          flex: none;
          width: 14px;
          height: 20px;
        }
      }

      .footer-right {
        display: flex;

        img {
          display: block;
          width: 100px;
          height: 50px;
        }
      }
    }
  }
}
</style>